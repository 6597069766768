.notes-container {
  border: 1px solid rgba(40, 40, 96, 0.2);
  border-radius: 16px;
  display: flex;
  overflow: hidden;
  height: calc(100%);
}

.notes-side-vertical-bar {
  width: 25%;
  /* background: rgba(221, 226, 233, 0.5); */
  border-right: 1px solid rgba(40, 40, 96, 0.2);
  border-radius: 16px 0px 0px 16px;
  padding: 1rem;
  height: calc(100%);
  display: flex;
  flex-direction: column;
}

.notes-side-vertical-bar-heading {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: left;
}

.all-notes-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 1.5rem;
  overflow-y: overlay;
}

.note-view-container {
  display: flex;
  flex-direction: column;
}
.note-view-date {
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: left;
}

.note-view-text {
  width: 100%;
  background: rgba(221, 226, 233, 0.7);
  padding: 1rem 0.75rem;
  margin-top: 0.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.note-view-container.current-open .note-view-text {
  cursor: not-allowed;
  background: #5f6ce1;
  color: white;
}

.editor-container {
  width: 75%;
  height: calc(100%);
  display: flex;
  flex-direction: column;
  position: relative;
}
