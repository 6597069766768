.google-calender-schedule-container {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;
  padding: 2rem;
  height: 100%;
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  max-width: 35rem;
}

.sub-heading-schedule {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  /* identical to box height, or 110% */

  display: flex;
  align-items: center;

  color: #282860;
}

.sync-with-google-calendar-action-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  /* or 21px */
  cursor: pointer;
  align-items: center;
  letter-spacing: 0.02em;

  color: #ec7440;

  opacity: 0.7;
}

.google-calendar-status-synced {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  /* or 21px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #5f6ce1;

  opacity: 0.7;
}

.last-synced-text {
  margin-right: 5px;
}

.circular-loading {
  width: 14px;
  margin-right: 5px;
}
