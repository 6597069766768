.text-input-field.session-with-field {
  width: 100%;
}

.modal-body.add-session-modal-body {
  padding-top: 24px;
}

.description-textarea {
  height: 5rem;
  width: 100%;
  resize: none;
}

.add-session-btn-container {
  margin-top: 40px;
}

.date-picker-container {
  width: 200px;
  display: inline-block;
}

.time-picker-container {
  width: 150px;
  display: inline-block;
  font-size: 0.9rem;
}

.seperator {
  margin: 0px 10px;
  display: inline-block;
}

.patient-dropdown {
  margin-top: 5px;
}

.patient-dropdown-item {
  font-weight: 500;
  opacity: 0.7;
  line-height: 180%;

  padding: 10px 22px;
  border: 1px solid #ccc;
  border-bottom: 0px;
  cursor: pointer;
}

.patient-dropdown-item:first-child {
  border-radius: 8px 8px 0px 0px;
}

.patient-dropdown-item:last-child {
  border-bottom: 1px solid #ccc;
  border-radius: 0px 0px 8px 8px;
}

.patient-dropdown-item:hover {
  background: rgba(221, 226, 233, 0.15);
}

.delete-session-confirmation-modal-btn-group {
  justify-content: flex-end;
  margin-top: 30px;
}

.delete-session-confirmation-modal-cancel {
  margin-left: 20px;
  background-color: rgba(95, 108, 225, 0.1);
  color: rgba(95, 108, 225, 1);
  border: 1px solid rgba(40, 40, 96, 0.15);
}

.delete-session-infotext {
  color: #282860;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.28px;
  opacity: 0.7;
}

.add-session-btn {
  padding: auto;
  border-width: 2px;
  border: none;
}

.save-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 200px;
}
