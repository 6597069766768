.sidebar-wrapper {
  background-color: #fefeff;
  width: 4rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 100vh;
  position: fixed;
}

.sidebar-logo {
  box-sizing: content-box;
  height: 2.4rem;
  padding: 1rem;
  position: absolute;
  top: 0;
}

.sidebar-menu {
  background-color: transparent;
  width: 100%;
  /* border-radius: 0 2rem 2rem 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  padding: 5rem 0;
  justify-content: center;
  margin-top: -3rem;
  position: relative;
  width: 3.4rem;
}

.sidebar-icon {
  height: 1.4rem;
  width: 1.4rem;
}

.sidebar-tab {
  height: 2.9rem;
  width: 2.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
}

.sidebar-tab.active {
  background-color: #fff;
  border-radius: 1rem;
}
