.client-summary-container {
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;
  background-color: #ffffff;
  padding: 1.5rem;
  display: flex;
  flex: 1 1 auto;
  gap: 1.5rem;
}

.summary-items-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.summary-item-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  flex: 1 1 auto;
}

.client-summary-item {
  background: rgba(201, 206, 250, 0.15);
  border: 1px solid rgba(40, 40, 96, 0.25);
  border-radius: 1rem;
  display: block;
  padding: 1.5rem 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client-summary-info-text {
  margin-left: 11px;
}

.summary-item-row {
  justify-content: space-between;
}

.client-summary-item:nth-child(2) {
  background: rgba(236, 116, 64, 0.15);
  border: 1px solid rgba(40, 40, 96, 0.25);
}

.client-summary-item:nth-child(3) {
  background: rgba(241, 208, 124, 0.15);
  border: 1px solid rgba(40, 40, 96, 0.25);
}

.client-summary-item:nth-child(4) {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(40, 40, 96, 0.25);
}

.sub-heading.client-summary-value {
  font-size: 1.125rem;
  color: #5f6ce1;
}

.client-summary-icon {
  width: 2rem;
  object-fit: contain;
  object-position: center;
}

.client-summary-icon img {
  width: 2rem;
  object-fit: contain;
  object-position: center;
}

.client-summary-reputation {
  border-left: 2px solid #dde2e9;
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reputation-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
  margin-left: 0.75rem;
}

.clients-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  /* or 25px */

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.7;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.reputation-clients {
  display: flex;
  justify-content: flex-start;
}

.reputation-clients img {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  object-position: center;
  margin-left: -0.875rem;
  border-radius: 50%;
}

.reputation-clients img:nth-child(1) {
  margin-left: 0px;
}

.reputation-clients-extra {
  height: 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  /* or 25px */

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.7;
  margin-left: 8px;
}
