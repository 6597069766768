.client-chart-heading {
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: left;
  padding-bottom: 40px;
  padding-top: 24px;
}

.back-arrow {
  margin-right: 23px;
  cursor: pointer;
}

.client-chart-body {
  display: grid;
  width: calc(100%);
  height: calc(100%);

  grid-template-areas: "client-details client-utilities-tab";
  grid-template-columns: 1.25fr 3.75fr;
  gap: 2rem;
}

.client-chart-body-realtime-transcript {
  display: grid;
  width: calc(100%);
  height: calc(100%);

  grid-template-areas: "client-details realtime-transcript-panel client-utilities-tab";
  grid-template-columns: 2fr 2.5fr 4fr;
  gap: 2rem;
}

/* @media screen and (max-width: 640px) {
  .client-chart-body {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
} */

.realtime-transcript-panel-container {
  border-radius: 24px;
  border: 1px solid rgba(40, 40, 96, 0.15);
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(41, 40, 45, 0.02);
  height: calc(100%);
  grid-area: realtime-transcript-panel;
  padding: 40px;
}

.realtime-panel-image-container {
}

.transcript-container-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1.2rem;

  color: #9090b5;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
}

.view-live-transcription {
  color: #5f6ce1;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
