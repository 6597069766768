/* .profile-pic-label {
  width: 100%;
  display: flex;
  background: linear-gradient(0deg, #e3e6eb, #e3e6eb),
    linear-gradient(0deg, #f0f3f7, #f0f3f7);
  border: 1px solid rgba(227, 230, 235, 1);
  padding: 20px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
} */

.signup-upload-profile_pic {
  width: 100%;
}
/* .signup-upload-profile_pic .MuiInputBase-root {
  justify-content: space-between;
} */

.signup-upload-profile_pic
  .MuiInputAdornment-root.MuiInputAdornment-positionStart {
  margin-top: 0 !important;
}
