.google-calendar-sync-error-modal-content {
  padding: 27px 40px 0 40px;
  width: 48.17%;
}

.google-calendar-sync-error-modal-content .modal-heading {
  line-height: 150%;
  /* identical to box height, or 30px */
  opacity: 0.8;
  margin-top: 25px;
}

.google-calendar-sync-error-modal-content .info-text {
  margin-top: 16px;
  opacity: 0.7;
}

.google-calendar-sync-error-modal-content .info-text.google-calendar-error-list-item {
  margin-top: 0px;
}

.calendar-sync-error-content {
  margin-left: 40px;;
}