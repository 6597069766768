/* Height: 916px */

.tabs-container {
  /* width: 60vw;
    height: 71vh;
    padding-bottom: 2rem; */
  grid-area: client-utilities-tab;

  border: 1px solid rgba(50, 51, 55, 0.1);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.04);
  border-radius: 32px;
  height: calc(100%);
  overflow-x: hidden;
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
}

.tab-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  border: 1px solid rgba(40, 40, 96, 0.1);
  border-bottom: none;
  padding: 0rem 2rem;
}

.tab-title {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "poppins";
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
}

.tab-panel {
  width: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  flex: 1 1;
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 0px 0px 1.5rem 1.5rem;
  padding: 2rem;
}
