/* .therapist-auth-form
  form[data-amplify-authenticator-signup]
  .amplify-button[data-variation="primary"] {
  display: none;
} */

.therapist-auth-form form[data-amplify-authenticator-signup] {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}
