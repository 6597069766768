.star-rating-container {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.25);
  border-radius: 0.75rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 1rem 0.75rem;
  width: 100%;
  justify-content: center;
}

.star-rating-container img {
  width: 100%;
  min-width: 0;
  object-fit: contain;
  object-position: center;
  height: 1.5rem;
  padding-left: 0.5rem;
}

.star-rating-container .inactive {
  opacity: 0.15;
}
