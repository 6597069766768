.modal-content.add-client-manually-modal-content {
  width: 67.7vw;
  /* height: 100vh; */
}

.modal-body.add-client-modal-manually {
  padding: 0px;
}

.general-section {
  height: 800px;
  padding: 24px 40px;
  overflow-y: scroll;
}

.general-section-subheading,
.history-section-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 180%;
  /* or 32px */

  color: #282860;
}

.gender-input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  /* identical to box height, or 29px */

  display: inline-block;

  color: #282860;
}

.gender-input-male {
  margin-right: 40px;
}

.contact-subheading {
  margin-top: 40px;
}

.history-section {
  padding: 24px 40px;
  height: 800px;
  overflow-y: scroll;
}

.add-new-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  /* or 25px */

  color: #ec7440;
}

.health-concerns {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  /* or 29px */
  display: inline-block;
  margin-left: 10px;

  color: #282860;

  opacity: 0.7;
}

.health-concerns-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  /* or 29px */

  margin: 24px 0px;

  color: #282860;

  opacity: 0.7;
}

.add-client-manually-save-button {
  background: #5f6ce1;
  border-radius: 8px;
  cursor: pointer;
  padding: 20px 24px;
  float: right;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
