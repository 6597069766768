.overall-wellbeing-report-wrapper {
  margin-top: 1rem;
  margin-bottom: 3rem;
  max-height: 16rem;
}

.overall-wellbeing-heading {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
}

/* Customize the Y-axis labels */
.custom-y-label {
  position: relative; /* Required for positioning pseudo-elements */
  display: flex;
  align-items: center;
}
.custom-y-label img {
  height: 2rem;
  width: 2rem;
  object-fit: contain;
  object-position: center;
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  padding: 1.25rem;
}
.popup-arrow {
  color: rgb(255, 255, 255);
  stroke: rgba(0, 0, 0, 0.5);
}
[role="tooltip"].popup-content {
  width: 25rem;
  box-shadow: rgba(0, 0, 0, 0.26) 0px 0px 6px;
  border-radius: 1.5rem;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.popup-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.popup-body-content {
  color: #282860;
  font-size: 1rem;
  font-weight: 500;
}

.popup-images-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.popup-image-labels {
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
}

.popup-image-labels img {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  object-position: center;
}

.popup-image-labels span {
  color: #282860;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
}
