.settings-wrapper {
  display: grid;
  width: calc(100%);
  height: calc(100%);

  grid-template-areas: "setting-list setting-details";
  gap: 1.5rem;

  grid-template-columns: 1.1fr 4fr;

  padding: 2rem;
  max-width: 1920px;
  max-height: 1200px;
  margin-left: 4rem;
}
