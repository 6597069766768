.trigger-map-notes {
  flex: 1 1 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow-y: overlay;
}
.session-trigger-map-header {
  position: relative;
}

.trigger-maps-notes-container {
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.trigger-note-input-container {
  border-radius: 1rem;
  border: 1px solid rgba(40, 40, 96, 0.15);
  background: #fff;
  box-shadow: 0rem 0.5rem 1rem 0rem rgba(41, 40, 45, 0.02);
  padding: 1rem;
}

.trigger-notes-textarea {
  color: #282860;
  font-size: 0.9rem;
  font-family: "Poppins";
  font-weight: 500;
  height: 3.75vw;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  width: 100%;
  overflow-y: overlay;
}

.trigger-date-delete-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trigger-note-date-container {
  color: #282860;
  font-size: 0.75rem;
  font-family: "Poppins";
  font-weight: 500;
  opacity: 0.5;
  margin-top: 1rem;
  text-align: center;
}
