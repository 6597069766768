.recent-client-interactions {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
}

.recent-client-interactions-header {
  display: flex;
  gap: 3rem;
  border-bottom: 1px solid rgba(40, 40, 96, 0.15);
  padding: 1.5rem 1.5rem 0px 1.5rem;
}

.recent-client-interactions-header .sub-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;

  display: block;
  align-items: center;
  text-align: center;

  color: #282860;

  opacity: 1;
  padding-bottom: 0.5rem;

  padding: 0px 0.5rem 0.5rem 0.5rem;

  cursor: pointer;
}

/* .recent-client-interactions-header .sub-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;

  align-items: center;

  color: #282860;
  opacity: 1;
  border-bottom: 2px solid #282860;
} */

.iteraction-body {
  flex: 1 1 auto;
  overflow-y: overlay;
}

.upcoming-followups-tab {
  display: grid;
  grid-template-columns: minmax(25rem, auto) 1fr;
  padding: 1.5rem;
  height: 100%;
  width: 100%;
  overflow-y: overlay;
}

.client-list {
  padding: 0px 2.5rem 0px 1rem;
  border-right: 2px solid #dde2e9;
  overflow-y: overlay;
}

.client-interaction {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
}

.client-interaction-active {
  background: rgba(171, 178, 242, 0.15);
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;
}

.interacted-client {
  margin-left: 0.5rem;
}

.img-icon img {
  margin: 3.5px 0px;
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
}

.interacted-client-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
  margin: 0.25rem 0px;
  margin-left: 1rem;
}

.client-history-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.client-history {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.client-history-row.row {
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.client-history {
  margin-left: 0.5rem;
}

.client-row.row {
  justify-content: flex-start;
}

.client-right-arrow {
  padding-top: 0.5rem;
}

.client-notes {
  padding-left: 2.5rem;
  overflow-y: scroll;
}

.client-notes-content-container {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;
  margin-bottom: 1rem;
}

.client-notes-content-header {
  background: #ffffff;
  border-bottom: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem 1.5rem 0px 0px;
  padding: 1rem 2rem;
}

.client-notes-content-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.client-notes-content {
  padding: 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.client-notes-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
  margin-bottom: 1rem;
}

.client-notes-heading span {
  margin-left: 0.5rem;
}

.client-interaction {
  margin-bottom: 1.5rem;
}

.client-notes-content-heading-datetime {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.client-notes-content-header {
  display: flex;
  justify-content: space-between;
}

.client-notes-content-header-summary-reply-group {
  display: flex;
  justify-content: flex-start;
}

.client-notes-content-header-summary-reply-group img {
  margin-left: 1rem;
  cursor: pointer;
  height: 1rem;
  object-fit: contain;
  object-position: center;
}
