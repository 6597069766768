.client-info-tile {
  background-color: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  box-shadow: 0rem 0.5rem 1rem rgba(41, 40, 45, 0.02);
  border-radius: 1.5rem;
  margin: 0.5rem;
  display: inline-block;
}

.info-list {
  margin: 1.5rem 0rem 1.5rem 1.5rem;
}

.tile-header-text {
  font-style: "poppins";
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: left;
  color: #282860;
  margin-bottom: 0.25rem;
}

.tile-header-info {
  font-style: "poppins";
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.02em;

  color: #282860;
  margin-bottom: 1.5rem;
}

.details-container {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
}

.client-tile-image {
  height: 4rem;
  border-radius: 50%;
}

.name-status-container {
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.client-tile-name {
  font-family: "poppins";
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #282860;
}

.client-tile-status {
  font-family: "poppins";
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: left;
}

.more-info-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.more-info-text {
  font-family: "poppins";
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: #ec7440;
  margin-right: 0.5rem;
}

.client-image-clients-tab {
  border-radius: 1rem;
  object-fit: contain;
  width: 5rem;
  height: 5rem;
  object-fit: contain;
  object-position: center;
  margin-bottom: 1rem;
}
