.active-clients-and-invite-box {
  background-color: #5f6ce1;
  border: 1px solid rgba(40, 40, 96, 0.25);
  box-shadow: 0px 0.5rem 1rem rgba(41, 40, 45, 0.08);
  border-radius: 1.5rem;
  display: inline-block;
  padding: 1.5rem;
  position: relative;
}

.paste-board-icon-container {
  position: relative;
  top: -4rem;
  text-align: center;
}

.paste-board-icon-container > img {
  width: 5rem;
  object-fit: contain;
  object-position: center;
}

.cloud-bg-intersect-container {
  position: absolute;
  z-index: 2;
  bottom: 1rem;
  left: 0px;
  height: 80%;
  width: 80%;
}

.cloud-bg-intersect-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: left;
}

.active-clients-and-invite-box-content {
  position: relative;
}

.add-or-invite-client-button {
  background-color: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.1);
  box-shadow: 0px 0.125rem 0.5rem rgba(41, 40, 45, 0.04);
  border-radius: 0.375rem;
  color: #5f6ce1;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  padding: 0.5rem;
  width: 100%;
}

.active-clients-and-invite-box-content .info-text-white {
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
}
