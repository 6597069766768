.report-task-and-advise-summary-subheading-text {
  height: 100%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  /* or 27px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  margin-left: 9rem;
  padding: 0 1rem;
}

.report-task-and-advise-summary {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;

  position: relative;

  display: grid;
  grid-template-columns: auto 1fr;
}

.task-textarea {
  height: 2rem;
  color: #282860;
  font-size: 0.9rem;
  font-family: "Poppins";
  font-weight: 500;
  border: none;
  line-height: 1rem;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.report-task-and-advise-summary-pasteboard {
  position: absolute;
  top: -2rem;
  left: 1.5rem;
}

.report-task-and-advise-summary-pasteboard > img {
  width: 7.5rem;
  height: 7.5rem;
  object-fit: contain;
  object-position: center;
}

.report-tasks-metrics {
  background: #ffffff;
  border-left: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.report-task-metric {
  text-align: center;
  padding: 1.5rem;
}

.report-task-metric-value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.625rem;

  text-align: center;

  color: #5f6ce1;

  /* margin-bottom: 24px; */
}

.report-task-metric-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;

  text-align: center;

  color: #282860;

  opacity: 0.5;
}

.report-task {
  padding: 0.5rem;
  padding-top: 1.25rem;
  border-bottom: 1px solid rgba(40, 40, 96, 0.15);
}

.report-task-text-group input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
}

.report-task-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  color: #282860;

  opacity: 0.8;

  margin-left: 1rem;
}

.report-task-creation-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  text-align: right;

  color: #282860;

  opacity: 0.5;
}

.search-bar-task-creation {
  width: unset !important;
}

.report-task-delete {
  display: none;
}

.report-task-creation-date {
  display: block;
}

.report-task:hover .report-task-delete {
  display: block;
  height: 0.875rem;
}

.report-task:hover .report-task-creation-date {
  display: none;
}
