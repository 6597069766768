.client-details {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.search-add-container {
  display: flex;
  justify-content: space-between;
}

.client-search-result {
  padding-top: 1.5rem;
  width: calc(100%);
  /* height: calc(100%); */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  max-height: calc(100%);
  overflow-y: overlay;
}
