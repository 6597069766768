.report-ai-analysis-allsessions{
  height: 100%;
}

.report-ai-analysis-pinned-div {
  height: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.view{
  border: red 1px solid;
}

.blue-button-medium{
  width: 164px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 12px;
  font-size: 14px;
  background-color: #282860;
  border-radius: 8px;
}

.report-ai-session-text {
  height: 40px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 230%;
  /* or 37px */


  color: #282860;

  opacity: 0.8;
  text-decoration: none;
}

.report-ai-weather {
  width: 32px;
  height: 32px;
}

.report-ai-session-number {
  height: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 230%;
  /* or 28px */

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.8;
}

.report-ai-session-date {
  height: 24px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 230%;
  /* or 23px */

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.5;
}

.report-ai-session {
  background: #FFFFFF;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 16px;
  padding: 12px;
  width: 240px;
  margin-right: 32px;
  cursor: pointer;
}

.session-link {
  text-decoration: none;
}

.manual-upload-root{
  display: flex;
  height: 640px;
  width: 960px;
  background-color: white;
  margin: 12vh auto;
  border-radius: 12px;
  border: none;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  gap: 12px;
}

.manual-upload-heading-div{
 height: 64px;
 display: flex;
 align-items: center;
}

.manual-upload-info-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.manual-upload-session-with-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.time-interval-row{
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 320px;
}
.manual-upload-desc-div{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.desc-textarea{
  height: 10vh;
}
.upload-space{
  display: flex;
  height: 280px;
  width: 100%;
  align-self: center;
 
  border: #ccc dashed 2px;
  justify-content: center;
  align-items: center;
}

.file-status-space{
  display: flex;
  height: 280px;
  width: 100%;
  align-self: center;
 
  /* border: #ccc dashed 2px; */
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  gap: 24px;

}

.file-status-box-container{
  display: flex;
  gap: 12px;

  width: fit-content;
}

.file-status-box{
  height: 180px;
  width: 180px;
  border-radius: 8px;
  border: 1px solid #eee;
    /* border: #ccc dashed 2px; */
 background-color: #ABB2F226;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transition: 400ms;
}
.upload-status-box:hover{
  gap: 20px;
}

.blue-box{
  padding: 4px 8px;
  background-color: #5F6CE1;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.upload-status-box{
  height: 180px;
  width: 180px;
  border-radius: 8px;
  border: 1px solid #eee;
    /* border: #ccc dashed 2px; */
 /* background-color: #ABB2F226; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: 200ms;
}