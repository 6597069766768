.signup-upload-certification {
  width: 100%;
}
/* .signup-upload-certification .MuiInputBase-root {
  justify-content: space-between;
} */

.signup-upload-certification
  .MuiInputAdornment-root.MuiInputAdornment-positionStart {
  margin-top: 0 !important;
}
