.profile-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2.8rem;
}
.profile-display:hover::after {
  content: "Edit";
}

.profile-display-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
}

.image-cropper {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.profile-display-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
}

.profile-display-info .heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 180%;
  /* or 36px */

  display: flex;
  align-items: center;

  color: #282860;
}

.profile-display-info .info-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  /* or 29px */

  display: flex;
  align-items: center;

  color: #575757;
}

.settings-input-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding-top: 1.2rem;
}

.normal-input {
  width: 24rem;
  height: 2.8rem;
  border-radius: 0.8rem;
  border: 2px solid #aaa;
  padding: 0rem 0.8rem;
}

.normal-dropdown {
  width: 24rem;
  height: 2.72rem;
  border: solid 1.4px #aaa;
  border-radius: 2rem;
}

.settings-input-pair-div {
  display: flex;
  flex-direction: column;
  margin: 1.2rem 0;
  gap: 0.4rem;
}

.sized-box {
  height: 2.4rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2.4rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.8rem;
}

.blue-button {
  height: 3rem;
  width: 12rem;
  background-color: #5f6ce1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
  color: white;
}

.profilepic-update-menu {
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: center;
  gap: 1.2rem;
  padding-left: 4.4rem;
}

.profilepic-update-menu input {
  display: none;
}

.yellow-button {
  height: 3rem;
  width: 12rem;
  background-color: #f1d07c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}

.profile-picture-big {
  width: 100px;
  height: 100px;
}

.react-multi-email {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.react-multi-email.focused {
  border-color: #85b7d9;
  background: #fff;
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  flex: 1;
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.4em 0.8em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}

.MuiInputBase-root {
  height: 2.72rem;
  border: solid 1.4px #aaa;
}
