.trigger-point-item-wrapper {
  width: 100%;
  display: flex;
  gap: 2rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.trigger-point-item-left-wrapper {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.trigger-point-emotion-and-time {
  display: flex;
  flex-direction: column;
}

.trigger-point-emotion {
  display: flex;
  gap: 1rem;
}

.trigger-point-emotion-header {
  font-size: 1.125rem;
  font-weight: 600;
}

.trigger-point-time {
  font-size: 1rem;
  font-weight: 500;
}

.trigger-point-transcript-header {
  font-size: 1rem;
  font-weight: 500;
}

.trigger-point-transcript-body {
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.7;
}

.trigger-point-insights {
  padding: 1rem;
  flex: 1;
  border-radius: 1.5rem;
  border: 1px solid rgba(40, 40, 96, 0.15);
  background: #fff;
}

.trigger-point-insights-image {
  float: left;
}

.trigger-point-insights-body {
  text-align: justify;
  font-size: 0.875rem;
  font-weight: 500;
  opacity: 0.7;
}

.trigger-point-emotion-dominant {
  display: flex;
}

.trigger-point-emotion-dominant img,
.trigger-point-emotion-dominant svg {
  width: 1.75rem;
  height: 1.75rem;
  object-fit: contain;
  object-position: center;
}
