.session-summary-container {
    background: #ffffff;
    border: 1px solid rgba(40, 40, 96, 0.15);
    border-radius: 1.5rem;
    padding: 1.5rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 150%;
    /* or 21px */
  
    letter-spacing: 0.02em;
  
    color: #282860;
  
    opacity: 0.7;
  }

  