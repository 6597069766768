.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5f6ce1;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.add-button-text {
  margin-left: 0.75rem;
  color: #ffffff;
  font-size: 0.875rem;
}
