.view-live-transcription {
    color: #5f6ce1;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: 0.28px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    cursor: pointer;
    user-select: none;
  }