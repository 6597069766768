.overview-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  margin-bottom: 1.5rem;
}
/* 
.overview-latest-activity {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
} */
/* .overview-latest-activity-log {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  color: #282860;

  opacity: 0.7;
  margin-left: 0.5rem;
} */

.overview-show-all {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;

  color: #5f6ce1;
  cursor: pointer;
}

.activity-log-info-text {
  opacity: 0.7;
}

.activity-log-timestamp {
  line-height: 0.875rem;
}

/* .overview-latest-activity-seperator {
  width: 2px;
  height: 1.5rem;
  background: #e4e7fd;
  border-radius: 2px;
  margin-left: 1.25rem;
} */

.tasks-container {
  margin-bottom: 2.5rem;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1rem;
  padding: 2rem;
}

.tasks-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
}

.tasks-show-all {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;

  color: #5f6ce1;
  cursor: pointer;
}

.report-tasks-list {
  overflow-x: hidden;
  overflow-y: overlay;
  max-height: 16rem;
}

.report-task-text-group input[type="checkbox"] {
  width: 1rem;
  align-items: center;
}

.report-task-text-descp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  color: #282860;

  opacity: 0.8;

  margin-left: 1rem;
}

.report-task-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  text-align: right;

  color: #282860;

  opacity: 0.5;
}
