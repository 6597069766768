.not-added {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-client-img {
  max-height: 12rem;
  /* margin-bottom: 30px; */
}

.no-client-text {
  font-style: italic;
}
