.search-bar{
    height: 2.5rem;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #DDE2E9;
    border-radius: 8px;
    width: 35vw;
}

.search-logo{
    padding: 16px 20px 16px 16px ;
}

.search-field{
    border: none;
    outline: none;
    background-color: #FFFFFF;
    color: #282860;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    margin-right: 16px;
}